import { useLocation } from "react-router-dom";
import { ButtonV1 } from "../atoms/button";
import SearchBar from "../atoms/searchBar";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { EditModal } from "../molecules/EditModal";
import snackbar from "../../api/snackbar/useSnackbar";
import {
  useBulkExportMutation,
  useBulkImportMutation,
} from "../../api/bulkUpload";
import { SelectInputBox } from "../atoms/selectInput";

interface props {
  qty: number;
  name: string;
  regex?: string;
  filterType?: string;
  sortType?: string;
  setAddNew?: Function;
  setSortType?: Function;
  setUpdateType?: Function;
  setFilterType?: Function;
  bulkUpdate?: Function;
  setRegex?: Function;
}

export default function AdminPageFilters({
  qty,
  name,
  setAddNew,
  setRegex,
  sortType,
  filterType,
  setSortType,
  setUpdateType,
  setFilterType,
  bulkUpdate,
  regex,
}: props) {
  const location = useLocation();
  const { pathname } = location;
  const [isImport, setIsImport] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [file, setFile] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [
    createImport,
    { isLoading: createImportIsLoading, isSuccess: createImportSuccess },
  ] = useBulkImportMutation({});

  const [
    createExport,
    { isLoading: createExportIsLoading, isSuccess: createExportSuccess },
  ] = useBulkExportMutation({});

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event?.target.files && event?.target?.files[0]);
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Import`,
        category: "User Interaction",
        action: "Button Click",
        label: `Import Button`,
      });
    }
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append("files", file);

      createImport(formData)
        .unwrap()
        .then((res) => {
          setIsImport(false);
          console.log("formSubmitted");
          snackbar.success(res?.data);
        })
        .catch((error) => {
          setIsImport(false);
          console.log(error);
        });
    }
  }

  function handleExport() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Export`,
        category: "User Interaction",
        action: "Button Click",
        label: `Export Button`,
      });
    }
    createExport({
      published: filterType || "published",
    })
      .unwrap()
      .then(async (res) => {
        console.log("data", res.data);
        if (res.data) {
          const a = document.createElement("a"); // Create a temporary anchor element
          a.href = res.data; // Set the URL as the anchor's href attribute
          a.download = "export.xlsx"; // Set the filename for the downloaded file

          document.body.appendChild(a); // Append the anchor element to the document body
          a.click(); // Programmatically trigger a click event on the anchor element

          // Clean up resources
          window.URL.revokeObjectURL(res.data); // Revoke the URL object to free up memory
          document.body.removeChild(a); // Remove the anchor element from the document body

          snackbar.success("Exported successfully");
        } else {
          // Handle error if the response status is not 200
          console.error("Unexpected response status:", res.status);
          snackbar.error("Export failed: Unexpected response status");
        }
      })
      .catch((error) => {
        console.error("Export failed:", error);
        snackbar.error("Export failed");
      })
      .finally(() => {
        setIsExport(false);
      });
  }


  const filterItems = [];
  pathname === "/admin" &&
    filterItems.push(
      { value: "active", name: "Publish" },
      { value: "inActive", name: "Unpublish" },
      { value: "isFeaturedOff", name: "FeaturedOff" },
    );

  pathname === "/admin/feature" &&
    filterItems.push(
      { value: "active", name: "Active" },
      { value: "inActive", name: "InActive" },
    );

  const title = pathname === "/admin" ? "newTitle" : "title";

  const sortItems = [
    { value: "createdAt", name: "last created" },
    { value: title, name: "title" },
  ];

  const updateItems = [{ value: "delete", name: "delete" }];
  pathname === "/admin" &&
    updateItems.push({ value: "publish", name: "Publish" });
  pathname === "/admin" &&
    updateItems.push({ value: "unpublish", name: "unpublish" });

  return (
    <div className="w-full whitespace-nowrap">
      <div className="flex justify-between w-full pb-4 ">
        <p className="text-[2rem] font-semibold flex flex-col capitalize">
          {name}
          <span className="text-sm text-left"> Total Count: {qty}</span>
        </p>
        <div className="flex gap-4">
          {(pathname === "/admin" || pathname === "/admin/feature") && (
            <div className="flex items-center">
              <h1 className="mr-4">Filter :</h1>
              <div className="w-max mt-1 mr-2 px-4 py-2 border rounded-xl">
                <SelectInputBox
                  items={filterItems}
                  value={filterItems[0].value}
                  onChange={setFilterType}
                />
              </div>
            </div>
          )}
          <div className="flex items-center">
            <h1 className="mr-4">Sort :</h1>
            <div className="w-max mt-1 mr-2 px-4 py-2 border rounded-xl">
              <SelectInputBox
                items={sortItems}
                value={sortItems[0].value}
                onChange={setSortType}
              />
            </div>
          </div>
          <SearchBar setRegex={setRegex} regex={regex} />
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center mb-2">
          <div className="w-max mr-2 m-auto px-4 py-2 border rounded-xl">
            <SelectInputBox
              items={updateItems}
              value={""}
              onChange={setUpdateType}
            />
          </div>
          <div className="w-min">
            <ButtonV1 color="blue" setActive={() => bulkUpdate && bulkUpdate()}>
              Go
            </ButtonV1>
          </div>
        </div>
        <div className="flex gap-4 w-min h-min">
          {pathname === "/admin" && (
            <ButtonV1 color="blue" setActive={() => setIsExport(true)}>
              Export
            </ButtonV1>
          )}
          {pathname === "/admin" && (
            <ButtonV1 color="red" setActive={() => setIsImport(true)}>
              bulk Add
            </ButtonV1>
          )}
          {pathname !== "/admin/users" && (
            <ButtonV1 color="blue" setActive={() => setAddNew && setAddNew()}>
              Add new
            </ButtonV1>
          )}
        </div>
        <EditModal open={isImport} handleClose={() => setIsImport(false)}>
          <form onSubmit={handleSubmit}>
            <input
              ref={fileInputRef}
              type="file"
              onChange={(e) => handleFileChange(e)}
              className="my-4 w-full"
            />
            <ButtonV1 color="blue" type="submit">
              upload
            </ButtonV1>
          </form>
        </EditModal>
        <EditModal open={isExport} handleClose={() => setIsExport(false)}>
          <p className="w-full text-center text-lg font-semibold my-6">
            You are going to <br />
            export all the Creatives
          </p>
          <div className="flex gap-4 px-4">
            <ButtonV1 color="red" setActive={() => setIsExport(false)}>
              Cancel
            </ButtonV1>
            <ButtonV1 color="blue" setActive={handleExport}>
              Export
            </ButtonV1>
          </div>
        </EditModal>
      </div>
    </div>
  );
}
