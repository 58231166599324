import {
  Approval,
  CalendarToday,
  Category,
  CollectionsBookmark,
  Construction,
  Loyalty,
  ManageSearch,
  Map,
  Public,
  ScreenRotation,
  Style,
  Translate,
  Tune,
} from "@mui/icons-material";
import { ButtonV1 } from "../atoms/button";
import { FilterTab, FilterTabV2 } from "./filterTab";
import { getAuthFromLocal, removeAuthFromLocal } from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import { useGetAdTypeQuery } from "../../api/filters/adType";
import { useGetBrandQuery } from "../../api/filters/brand";
import { useGetCategoryQuery } from "../../api/filters/category";
import { useGetFeatureQuery } from "../../api/filters/feature";
import { useGetCountryQuery } from "../../api/filters/country";
import { useGetLanguageQuery } from "../../api/filters/language";
import { useGetOrientationQuery } from "../../api/filters/orientation";
import { useGetRegionQuery } from "../../api/filters/region";
import { useGetStageQuery } from "../../api/filters/stage";
import { useGetSubBrandQuery } from "../../api/filters/subBrand";
import { authDetails } from "../../api/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutFilterHandler,
  storeHandler,
} from "../../api/store/storeActions";
import { useGetBrandCollabQuery } from "../../api/filters/brandCollab";
import { FiltersType } from "../../types/types";
import { useGetYearQuery } from "../../api/filters/year";

interface ItemSchema {
  _id: string;
  title: string;
}

interface props {
  regex: string;
  pageNo: number;
  setPageNo: Function;
  setRegex: Function;
  setKeyword: Function;
  setIsLoading: Function;
}

export function SidebarUser({
  regex,
  pageNo,
  setPageNo,
  setRegex,
  setKeyword,
  setIsLoading,
}: props) {
  const navigate = useNavigate();
  let data: authDetails = getAuthFromLocal();
  !data && navigate("/");

  const dispatch = useDispatch<any>();
  const adType = useSelector((state: any) => state.data.adType);
  const brand = useSelector((state: any) => state.data.brand);
  const category = useSelector((state: any) => state.data.category);
  const country = useSelector((state: any) => state.data.country);
  const feature = useSelector((state: any) => state.data.feature);
  const language = useSelector((state: any) => state.data.language);
  const orientation = useSelector((state: any) => state.data.orientation);
  const region = useSelector((state: any) => state.data.region);
  const stage = useSelector((state: any) => state.data.stage);
  const subBrand = useSelector((state: any) => state.data.subBrand);
  const brandCollab = useSelector((state: any) => state.data.brandCollab);
  const year = useSelector((state: any) => state.data.year);

  const { data: AdTypeData } = useGetAdTypeQuery({
    title: regex,
    isPublished: true,
    active: true,
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: BrandData } = useGetBrandQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    year: year?.map((item: ItemSchema) => item.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: CategoryData } = useGetCategoryQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: FeatureData } = useGetFeatureQuery({
    title: regex,
    isPublished: true,
    active: true,
    filterType: "active",
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: LanguageData } = useGetLanguageQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: OrientationData } = useGetOrientationQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: RegionData } = useGetRegionQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: StageData } = useGetStageQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: CountryData } = useGetCountryQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    subBrandId: subBrand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: SubBrandData } = useGetSubBrandQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: BrandCollabData } = useGetBrandCollabQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    year: year?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    sortType: "title",
    sortOrder: 1,
  });

  const { data: YearData } = useGetYearQuery({
    title: regex,
    isPublished: true,
    active: true,
    adTypesId: adType?.map((item: ItemSchema) => item.title),
    categoryId: category?.map((item: ItemSchema) => item.title),
    countryId: country?.map((item: ItemSchema) => item.title),
    featuresId: feature?.map((item: ItemSchema) => item.title),
    languageId: language?.map((item: ItemSchema) => item.title),
    orientationId: orientation?.map((item: ItemSchema) => item.title),
    regionsId: region?.map((item: ItemSchema) => item.title),
    stagesId: stage?.map((item: ItemSchema) => item?.title),
    brandId: brand?.map((item: ItemSchema) => item.title),
    brandCollab: brandCollab?.map((item: ItemSchema) => item?.title),
    sortType: "title",
    sortOrder: 1,
  });

  function adTypeHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Adtype_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (adType?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        adType: adType.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("adTypeRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        adType: [...adType, newItem],
      };
      //console.log("adTypeAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function brandHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Brand_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (brand?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        brand: brand?.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("brandRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        brand: [...brand, newItem],
      };
      //console.log("brandAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function brandCollabHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_BrandCollab_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (brandCollab?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        brandCollab: brandCollab.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("brandCollabRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        brandCollab: [...brandCollab, newItem],
      };
      //console.log("brandCollabAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function categoryHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Category_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (category?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        category: category.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("categoryRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        category: [...category, newItem],
      };
      //console.log("categoryAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function countryHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Country_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (country?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        country: country.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("countryRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        country: [...country, newItem],
      };
      //console.log("countryAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function featureHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Feature_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (feature?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        feature: feature.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("categoryRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        feature: [...feature, newItem],
      };
      //console.log("categoryAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function languageHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Language_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (language?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        language: language.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("languageRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        language: [...language, newItem],
      };
      //console.log("languageAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function orientationHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Orientation_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (orientation?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        orientation: orientation.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("orientationRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        orientation: [...orientation, newItem],
      };
      //console.log("orientationAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function regionHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Region_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (region?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        region: region.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("regionRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        region: [...region, newItem],
      };
      //console.log("regionAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function stageHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_State_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (stage?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        stage: stage.filter((item: ItemSchema) => item.title !== newItem.title),
      };
      //console.log("stageRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        stage: [...stage, newItem],
      };
      //console.log("stageAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function subBrandHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_SubBrand_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (subBrand?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        subBrand: subBrand.filter(
          (item: ItemSchema) => item.title !== newItem.title
        ),
      };
      //console.log("subBrandRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        subBrand: [...subBrand, newItem],
      };
      //console.log("subBrandAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function yearHandleChange(newItem: ItemSchema) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: `Engage_Tap_Year_${newItem.title}`,
        category: "User Interaction",
        action: "Button Click",
        label: "Filter Button",
      });
    }
    setPageNo(1);
    setIsLoading(true);
    if (year?.some((item: ItemSchema) => item.title === newItem.title)) {
      const data = {
        year: year.filter((item: ItemSchema) => item.title !== newItem.title),
      };
      //console.log("yearRemoved", data);
      dispatch(storeHandler(data));
    } else {
      const data = {
        year: [...year, newItem],
      };
      //console.log("yearAdded", data);
      dispatch(storeHandler(data));
    }
  }

  function cleanHandler() {
    setRegex("");
    setKeyword("");
    dispatch(logoutFilterHandler());
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Engage_Tap_Clear",
        category: "User Interaction",
        action: "Button Click",
        label: "Clear Button",
      });
    }
    //console.log("clenind");
  }

  function logoutHandler() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Engage_Tap_Logout",
        category: "User Interaction",
        action: "Button Click",
        label: "Logout Button",
      });
    }
    removeAuthFromLocal();
    dispatch(logoutFilterHandler());
    navigate("/");
  }

  return (
    <div className="sticky top-[10vh] w-[20%] h-[90vh] bg-slate-50 px-[2vw] py-[4vh] flex flex-col shadow-lg justify-between">
      <div className="h-[90%] relative overflow-hidden">
        <div className="flex justify-between w-full h-max pb-6 sticky top-0 bg-slate-50 z-10">
          <p className="flex items-center gap-1 font-semibold text-2xl">
            <Tune className="text-black" style={{ fontSize: "1.8rem" }} />
            Filters
          </p>
          <div>
            <ButtonV1 color="blue" setActive={() => cleanHandler()}>
              clear
            </ButtonV1>
          </div>
        </div>
        <div className="h-[90%] pr-2 overflow-y-auto overflow-x-hidden scrollerRed">
          <FilterTab
            child="Region"
            icon={<Public style={{ fontSize: "20px", marginLeft: "10px" }} />}
            handleChange={regionHandleChange}
            reduxData={region}
            data={RegionData?.data?.data}
          />

          {CountryData?.data?.data.length !== 0 && region.length !== 0 && (
            <FilterTab
              child="Country"
              icon={<Map style={{ fontSize: "20px", marginLeft: "10px" }} />}
              handleChange={countryHandleChange}
              reduxData={country}
              data={CountryData?.data?.data}
            />
          )}

          <FilterTab
            child="AdType"
            icon={
              <ManageSearch style={{ fontSize: "20px", marginLeft: "10px" }} />
            }
            handleChange={adTypeHandleChange}
            reduxData={adType}
            data={AdTypeData?.data?.data}
          />

          <FilterTab
            child="Category"
            icon={<Category style={{ fontSize: "20px", marginLeft: "10px" }} />}
            handleChange={categoryHandleChange}
            reduxData={category}
            data={CategoryData?.data?.data}
          />

          <FilterTab
            child="Feature"
            icon={
              <Construction style={{ fontSize: "20px", marginLeft: "10px" }} />
            }
            handleChange={featureHandleChange}
            reduxData={feature}
            data={FeatureData?.data?.data}
          />

          <FilterTab
            child="Brand"
            icon={<Style style={{ fontSize: "20px", marginLeft: "10px" }} />}
            handleChange={brandHandleChange}
            reduxData={brand}
            data={BrandData?.data?.data}
          />

          {SubBrandData?.data?.data.length !== 0 && brand.length !== 0 && (
            <FilterTab
              child="SubBrand"
              icon={
                <Loyalty style={{ fontSize: "20px", marginLeft: "10px" }} />
              }
              handleChange={subBrandHandleChange}
              reduxData={subBrand}
              data={SubBrandData?.data?.data}
            />
          )}

          {BrandCollabData?.data?.data.length !== 0 && brand.length !== 0 && (
            <FilterTab
              child="Brand Collab"
              icon={
                <CollectionsBookmark
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                />
              }
              handleChange={brandCollabHandleChange}
              reduxData={brandCollab}
              data={BrandCollabData?.data?.data}
            />
          )}

          <FilterTab
            child="Language"
            icon={
              <Translate style={{ fontSize: "20px", marginLeft: "10px" }} />
            }
            handleChange={languageHandleChange}
            reduxData={language}
            data={LanguageData?.data?.data}
          />

          <FilterTab
            child="Orientation"
            icon={
              <ScreenRotation
                style={{ fontSize: "20px", marginLeft: "10px" }}
              />
            }
            handleChange={orientationHandleChange}
            reduxData={orientation}
            data={OrientationData?.data?.data}
          />

          <FilterTab
            child="Stage"
            icon={<Approval style={{ fontSize: "20px", marginLeft: "10px" }} />}
            handleChange={stageHandleChange}
            reduxData={stage}
            data={StageData?.data?.data}
          />

          <FilterTab
            child="Year"
            icon={
              <CalendarToday style={{ fontSize: "20px", marginLeft: "10px" }} />
            }
            handleChange={yearHandleChange}
            reduxData={year}
            data={YearData?.data?.data}
          />
        </div>
      </div>
      <button
        type={"button"}
        onClick={() => logoutHandler()}
        className={`w-1/2 mx-auto my-1 flex gap-1 justify-center items-center capitalize p-2 px-4 border-none rounded-xl whitespace-nowrapl hover:bg-[#a90027] text-[#DA1A42] hover:text-white`}
      >
        log out
      </button>
    </div>
  );
}

export function SidebarAdmin() {
  let data: authDetails = getAuthFromLocal();
  const navigate = useNavigate();

  function logoutHandler() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Engage_Tap_Logout",
        category: "User Interaction",
        action: "Button Click",
        label: "Logout Button",
      });
    }
    removeAuthFromLocal();
    navigate("/");
  }
  // //console.log(data, "is data");

  !data && navigate("/");
  data && data?.roles === "user" && navigate("/");

  return (
    <div className="sticky top-[10vh] w-[20vw] max-[800px]:w-[30vw] h-[90vh] bg-gray-900 px-[2vw] py-[2vh] flex flex-col shadow-lg justify-between">
      <div className="h-[90%] relative">
        <div className="text-gray-500 flex justify-between w-full h-max pb-4 sticky top-0 bg-gray-900">
          <p className="flex items-center gap-1 font-semibold text-xl">
            Filters
          </p>
        </div>
        <div className="overflow-y-auto h-[90%] scrollerRed">
          <FilterTabV2 href="/admin"> creative </FilterTabV2>
          <FilterTabV2 href="/admin/adType"> adType </FilterTabV2>
          <FilterTabV2 href="/admin/brand"> brand </FilterTabV2>
          <FilterTabV2 href="/admin/subBrand"> subBrand </FilterTabV2>
          <FilterTabV2 href="/admin/brandCollab"> brandCollab </FilterTabV2>
          <FilterTabV2 href="/admin/dtLevels"> Dt Level </FilterTabV2>
          <FilterTabV2 href="/admin/domain"> Domain </FilterTabV2>
          <FilterTabV2 href="/admin/feature"> feature </FilterTabV2>
          <FilterTabV2 href="/admin/keyword"> keyword </FilterTabV2>
          <FilterTabV2 href="/admin/specialKeyword">specialKeyword</FilterTabV2>
          <FilterTabV2 href="/admin/category"> category </FilterTabV2>
          <FilterTabV2 href="/admin/creativeFormat">creativeFormat</FilterTabV2>
          <FilterTabV2 href="/admin/region"> region </FilterTabV2>
          <FilterTabV2 href="/admin/country"> country </FilterTabV2>
          <FilterTabV2 href="/admin/language"> language </FilterTabV2>
          <FilterTabV2 href="/admin/orientation"> orientation </FilterTabV2>
          <FilterTabV2 href="/admin/stage"> stage </FilterTabV2>
          <FilterTabV2 href="/admin/year"> year </FilterTabV2>
          <FilterTabV2 href="/admin/bulkKeywordUpdate">
            Bulk Keyword Update
          </FilterTabV2>
          {data && data?.roles === "admin" && (
            <FilterTabV2 href="/admin/users"> Users </FilterTabV2>
          )}
        </div>
      </div>
      <button
        type={"button"}
        onClick={() => logoutHandler()}
        className={`w-1/2 min-w-max mx-auto my-1 flex gap-1 justify-center items-center capitalize p-2 px-4 border-none rounded-xl whitespace-nowrapl hover:bg-[#a90027] text-[#DA1A42] hover:text-white`}
      >
        log out
      </button>
    </div>
  );
}
