import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./creativeModal.css";
import { CreativeType, FiltersType } from "../../types/types";
import { FileField, InputField } from "../atoms/inputField";
import { useFormik } from "formik";
import snackbar from "../../api/snackbar/useSnackbar";
import {
  useCreateCreativeMutation,
  useUpdateCreativeMutation,
} from "../../api/creative";
import { CreativeSchema } from "../../validationSchema/creativeSchema";
import { SelectBox, SelectBoxV1 } from "../atoms/selectBox";
import { ButtonV1 } from "../atoms/button";
import { useGetAdTypeQuery } from "../../api/filters/adType";
import { useGetBrandQuery } from "../../api/filters/brand";
import { useGetCategoryQuery } from "../../api/filters/category";
import { useGetCountryQuery } from "../../api/filters/country";
import { useGetLanguageQuery } from "../../api/filters/language";
import { useGetOrientationQuery } from "../../api/filters/orientation";
import { useGetRegionQuery } from "../../api/filters/region";
import { useGetSubBrandQuery } from "../../api/filters/subBrand";
import { useGetFeatureQuery } from "../../api/filters/feature";
import { useGetKeywordQuery } from "../../api/filters/keyword";
import { useGetSpecialKeywordQuery } from "../../api/filters/specialKeyword";
import { useEffect } from "react";
import { useGetCreativeFormatQuery } from "../../api/filters/creativeFormat";
import { useGetYearQuery } from "../../api/filters/year";
import { useGetStageQuery } from "../../api/filters/stage";
import { useGetBrandCollabQuery } from "../../api/filters/brandCollab";
import { useGetDtLevelQuery } from "../../api/filters/dtLevels";

interface propsV2 {
  item?: CreativeType;
  open: boolean;
  handleClose: Function;
}

interface ItemSchema {
  id: string;
  title: string;
}

export function AddCreativeModal({ open, handleClose }: propsV2) {
  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateCreativeMutation({});

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
  };

  const Formik = useFormik({
    initialValues: {
      adTypes: [],
      brandId: "",
      brandCollab: [],
      categories: [],
      countries: [],
      creativeFormats: [],
      features: [],
      gDriveLink: "",
      isPublished: false,
      keywords: [],
      lang: "",
      newTitle: "",
      orientation: "",
      regionId: "",
      subBrands: [],
      specialKeywords: [],
      title: "",
      year: "",
      DT: "",
      videFileName: "",
      vimeoLink: "",
      stage: "",
      videoLink: "",
      thumbImage: "",
      thumbs: [""],
    },
    enableReinitialize: false,
    validationSchema: CreativeSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: `Engage_Tap_Add_Creative`,
          category: "User Interaction",
          action: "Button Click",
          label: `Add Creative Button`,
        });
      }
      create(values)
        .unwrap()
        .then((res) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const { data: AdTypeData } = useGetAdTypeQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: BrandData } = useGetBrandQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: BrandCollabData } = useGetBrandCollabQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: CategoryData } = useGetCategoryQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: KeywordData } = useGetKeywordQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: LanguageData } = useGetLanguageQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: OrientationData } = useGetOrientationQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: RegionData } = useGetRegionQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: SpecialKeywords } = useGetSpecialKeywordQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: FeatureData } = useGetFeatureQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: CreativeFormatData } = useGetCreativeFormatQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: DtLevelData } = useGetDtLevelQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: YearData } = useGetYearQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: StageData } = useGetStageQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { refetch: subBrandsRefetch, data: SubBrandData } = useGetSubBrandQuery(
    {
      brand: Formik.values.brandId,
      sortType: "title",
      sortOrder: 1,
    }
  );
  const { refetch: countryRefetch, data: CountryData } = useGetCountryQuery({
    region: Formik.values.regionId,
    sortType: "title",
    sortOrder: 1,
  });

  useEffect(() => {
    subBrandsRefetch();
    countryRefetch();
  }, [Formik.values.brandId, Formik.values.regionId]);

  console.log("Formik.values", Formik);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} height={"90vh"} overflow={"scroll"}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
          role="presentation"
        >
          <Close className="" />
        </div>
        <h1 className="font-semibold text-xl py-6 pb-2 px-8 mb-4 border-b">
          Creative New
        </h1>
        <form
          className="w-full flex flex-wrap p-8 pb-0 pt-0 justify-between"
          noValidate
          onSubmit={Formik.handleSubmit}
        >
          <InputField
            width="half"
            name="title"
            label="title"
            type="text"
            placeholder="Title"
            formik={Formik}
          />
          <InputField
            width="half"
            name="newTitle"
            label="new title"
            type="text"
            placeholder="New title"
            formik={Formik}
          />
          <FileField
            name="video"
            label="Video"
            type="file"
            accept="video/*"
            formik={Formik}
          />
          <SelectBox
            formik={Formik}
            label="AdType"
            name="adTypes"
            options={AdTypeData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBox
            formik={Formik}
            label="Category"
            name="categories"
            options={CategoryData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBoxV1
            formik={Formik}
            label="Brands"
            name="brandId"
            options={BrandData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBox
            formik={Formik}
            label="Sub Brands"
            name="subBrands"
            options={SubBrandData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBox
            formik={Formik}
            label="Brand Collab"
            name="brandCollab"
            options={BrandCollabData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBoxV1
            formik={Formik}
            label="region"
            name="regionId"
            options={RegionData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBox
            formik={Formik}
            label="Country"
            name="countries"
            options={CountryData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBox
            formik={Formik}
            label="Feature"
            name="features"
            options={FeatureData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBoxV1
            formik={Formik}
            label="orientation"
            name="orientation"
            options={OrientationData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBoxV1
            formik={Formik}
            label="Stage"
            name="stage"
            options={StageData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBox
            formik={Formik}
            label="Keyword"
            name="keywords"
            options={KeywordData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBoxV1
            formik={Formik}
            label="Language"
            name="lang"
            options={LanguageData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBoxV1
            formik={Formik}
            label="Year"
            name="year"
            options={YearData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />

          <SelectBox
            formik={Formik}
            label="DT Levels"
            name="DT"
            options={DtLevelData?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <SelectBox
            formik={Formik}
            label="CreativeFormats"
            name="creativeFormats"
            options={CreativeFormatData?.data?.data.map(
              (item: FiltersType) => ({
                id: item._id,
                title: item.title,
              })
            )}
          />
          <InputField
            width="half"
            name="gDriveLink"
            label="G Drive Link"
            type="text"
            placeholder="G Drive Link"
            formik={Formik}
          />
          <InputField
            width="half"
            name="vimeoLink"
            label="vimeo Link"
            type="text"
            placeholder="Vimeo Link"
            formik={Formik}
          />
          <SelectBox
            formik={Formik}
            label="Special Keyword"
            name="specialKeywords"
            options={SpecialKeywords?.data?.data.map((item: FiltersType) => ({
              id: item._id,
              title: item.title,
            }))}
          />
          <InputField
            width="half"
            formik={Formik}
            label="Video File Name"
            name="videFileName"
            type="text"
            placeholder="Video File Name"
          />
          <div className="w-full flex justify-end p-8 pt-0">
            <div className="w-max">
              <ButtonV1 color="blue" type="submit">
                Save
              </ButtonV1>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export function EditCreativeModal({ item, open, handleClose }: propsV2) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
  };

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateCreativeMutation({});

  const Formik = useFormik({
    initialValues: {
      adTypes: item?.adTypes?.map((item) => item) || [],
      brandId: item?.brandId,
      brandCollab: item?.brandCollab || [],
      categories: item?.categories?.map((item) => item) || [],
      countries: item?.countries?.map((item) => item) || [],
      creativeFormats: item?.creativeFormats?.map((item) => item) || [],
      features: item?.features?.map((item) => item) || [],
      gDriveLink: item?.gDriveLink,
      isPublished: item?.isPublished,
      keywords: item?.keywords?.map((item) => item) || [],
      lang: item?.lang,
      newTitle: item?.newTitle,
      orientation: item?.orientation,
      regionId: item?.regionId,
      subBrands: item?.subBrands.map((item) => item) || [],
      specialKeywords: item?.specialKeywords?.map((item) => item) || [],
      title: item?.title,
      year: item?.year,
      DT: item?.DT,
      vimeoLink: item?.vimeoLink,
      videFileName: item?.videFileName,
      stage: item?.stage,
      videoLink: item?.videoLink ?? "",
      thumbImage: item?.thumbImage ?? "",
      thumbs: item?.thumbs || [""],
    },
    enableReinitialize: true,
    validationSchema: CreativeSchema,
    onSubmit: (values) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: `Engage_Tap_Edit_Creative`,
          category: "User Interaction",
          action: "Button Click",
          label: `Edit Creative Button`,
        });
      }
      update({ id: item?._id, body: values })
        .unwrap()
        .then((res) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  console.log("formik", Formik);

  const { data: AdTypeData, isFetching: adTypesIsLoading } = useGetAdTypeQuery(
    {}
  );
  const { data: BrandData, isFetching: brandIsLoading } = useGetBrandQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: BrandCollabData, isFetching: brandCollabIsLoading } =
    useGetBrandCollabQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: CategoryData, isFetching: categoryIsLoading } =
    useGetCategoryQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: KeywordData, isFetching: keyWordIsLoading } =
    useGetKeywordQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: LanguageData, isFetching: languageIsLoading } =
    useGetLanguageQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: OrientationData, isFetching: orientationIsLoading } =
    useGetOrientationQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: RegionData, isFetching: regionIsLoading } = useGetRegionQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: FeatureData, isFetching: featureIsLoading } =
    useGetFeatureQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: SpecialKeywords, isFetching: specialKeywordIsLoading } =
    useGetSpecialKeywordQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: CreativeFormatData, isFetching: creativeFormatIsLoading } =
    useGetCreativeFormatQuery({
      sortType: "title",
      sortOrder: 1,
    });
  const { data: DtLevelData } = useGetDtLevelQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: StageData, isFetching: stageIsLoading } = useGetStageQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const { data: YearData, isFetching: yearIsLoading } = useGetYearQuery({
    sortType: "title",
    sortOrder: 1,
  });
  const {
    refetch: subBrandsRefetch,
    data: SubBrandData,
    isFetching: subBrandIsLoading,
  } = useGetSubBrandQuery({
    brand: Formik.values.brandId,
    sortType: "title",
    sortOrder: 1,
  });
  const {
    refetch: countryRefetch,
    data: CountryData,
    isFetching: countryIsLoading,
  } = useGetCountryQuery({
    region: Formik.values.regionId,
    sortType: "title",
    sortOrder: 1,
  });

  useEffect(() => {
    subBrandsRefetch();
    countryRefetch();
  }, [Formik.values.brandId, Formik.values.regionId]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} height={"90vh"} overflow={"scroll"}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
          role="presentation"
        >
          <Close className="" />
        </div>
        <h1 className="font-semibold text-xl py-6 pb-2 px-8 mb-4 border-b">
          Update Creative
        </h1>
        {adTypesIsLoading ||
        brandIsLoading ||
        brandCollabIsLoading ||
        categoryIsLoading ||
        countryIsLoading ||
        keyWordIsLoading ||
        languageIsLoading ||
        orientationIsLoading ||
        regionIsLoading ||
        featureIsLoading ||
        specialKeywordIsLoading ||
        stageIsLoading ||
        creativeFormatIsLoading ||
        stageIsLoading ||
        yearIsLoading ||
        subBrandIsLoading ? (
          <p className="w-full h-full text-2xl text-center">Loading..</p>
        ) : (
          <form
            className="w-full flex flex-wrap p-8 pb-0 pt-0 justify-between"
            noValidate
            onSubmit={Formik.handleSubmit}
          >
            <InputField
              width="half"
              name="title"
              label="title"
              type="text"
              placeholder="Title"
              formik={Formik}
            />
            <InputField
              width="half"
              name="newTitle"
              label="new title"
              type="text"
              placeholder="New title"
              formik={Formik}
            />
            <FileField
              name="video"
              label="Video"
              type="file"
              accept="video/*"
              formik={Formik}
            />
            <SelectBox
              formik={Formik}
              label="AdType"
              name="adTypes"
              options={AdTypeData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="Category"
              name="categories"
              options={CategoryData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBoxV1
              formik={Formik}
              label="Brands"
              name="brandId"
              options={BrandData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="Sub Brands"
              name="subBrands"
              options={SubBrandData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="Brand Collab"
              name="brandCollab"
              options={BrandCollabData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBoxV1
              formik={Formik}
              label="region"
              name="regionId"
              options={RegionData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="Country"
              name="countries"
              options={CountryData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="Feature"
              name="features"
              options={FeatureData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBoxV1
              formik={Formik}
              label="orientation"
              name="orientation"
              options={OrientationData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBoxV1
              formik={Formik}
              label="Stage"
              name="stage"
              options={StageData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="Keyword"
              name="keywords"
              options={KeywordData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBoxV1
              formik={Formik}
              label="Language"
              name="lang"
              options={LanguageData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBoxV1
              formik={Formik}
              label="Year"
              name="year"
              options={YearData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="DT Levels"
              name="DT"
              options={DtLevelData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <SelectBox
              formik={Formik}
              label="CreativeFormats"
              name="creativeFormats"
              options={CreativeFormatData?.data?.data.map(
                (item: FiltersType) => ({
                  id: item._id,
                  title: item.title,
                })
              )}
            />
            <InputField
              width="half"
              name="gDriveLink"
              label="G Drive Link"
              type="text"
              placeholder="G Drive Link"
              formik={Formik}
            />
            <InputField
              width="half"
              name="vimeoLink"
              label="vimeo Link"
              type="text"
              placeholder="Vimeo Link"
              formik={Formik}
            />
            <SelectBox
              formik={Formik}
              label="Special Keyword"
              name="specialKeywords"
              options={SpecialKeywords?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.title,
              }))}
            />
            <InputField
              width="half"
              formik={Formik}
              label="Video File Name"
              name="videFileName"
              type="text"
              placeholder="Video File Name"
            />
            <div className="w-full flex justify-end p-8 pt-0">
              <div className="w-max">
                <ButtonV1 color="blue" type="submit">
                  Save
                </ButtonV1>
              </div>
            </div>
          </form>
        )}
      </Box>
    </Modal>
  );
}
