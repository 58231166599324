import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutHandler } from "../api/auth/authActions";
import snackbar from "../api/snackbar/useSnackbar";
import { getFromLocal, removeAuthCookie } from "../utils/storage";
import { baseUrl } from "../config/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getFromLocal("accessToken");
    accessToken && headers.set("authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch(logoutHandler({ isSession: true }));
    snackbar.error(result?.error?.message || "Unauthorized");
    removeAuthCookie();
  }

  if (result?.error?.status === 403) {
    api.dispatch(logoutHandler({ isSession: true }));
    removeAuthCookie();
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "User",
    "Creative",
    "Category",
    "AdType",
    "Brand",
    "Country",
    "Feature",
    "Filter",
    "Keyword",
    "Language",
    "Orientation",
    "Region",
    "Stage",
    "SubBrand",
    "SpecialKeyword",
    "BrandCollab",
    "CreativeFormat",
    "Year",
    "DtLevel",
    "DomainLink",
    "BulkKeywordUpdate",
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
